<template>
	<div class="b-page-wrapper administrator">
		<div class="b-head-block b-head-block--link">
			<div class="b-container">
				<div class="b-head-block__wrapper">
					<router-link class="b-link b-link--header" :to="'/Companies'" title="">
						<iconAngleleftSvg class="b-icon b-icon--header icon-angle-left" />
						<span>Назад</span>
					</router-link>
					<h1 class="b-title b-title--h1">{{ !$route.params.id ? "Добавление справочника МПНКО" : "Редактирование справочника МПНКО" }}
					</h1>
				</div>
			</div>
		</div>
		<div class="b-container">
			<div class="b-window">
				<div class="b-form" action="/">
					<div class="b-form__first-lavel">
						<div class="b-form__group b-form__group">
							<div class="b-form__left">
								<label class="b-label" for="role">Наименование
								</label>
							</div>
							<div class="b-form__right">
								<div class="b-input">
									<input v-model="obj.name" :disabled="obj.roleId == 1" class="b-input__input-field" id="name" type="text" placeholder="Введите наименование справочника" name="name"/>
									<i class="b-icon b-icon--check icon-check"></i><i class="b-icon b-icon--close icon-close"></i>
								</div>
							</div>
						</div>
						<div class="b-form__group b-form__group">
							<div class="b-form__left">
								<label class="b-label" for="name">ИНН
								</label>
							</div>
                     <div class="b-form__right">
								<div class="b-input">
									<input v-model="obj.inn" :disabled="obj.roleId == 1" class="b-input__input-field" id="name" type="number" placeholder="Введите ИНН" name="name"/>
									<i class="b-icon b-icon--check icon-check"></i><i class="b-icon b-icon--close icon-close"></i>
								</div>
							</div>
						</div>
						<div class="b-form__group b-form__group">
							<div class="b-form__left">
								<label class="b-label" for="name">Адрес
								</label>
							</div>
							<div class="b-form__right">
								<div class="b-input">
									<input v-model="obj.address" :disabled="obj.roleId == 1" class="b-input__input-field" id="name" type="text" placeholder="Введите адрес" name="name"/>
									<i class="b-icon b-icon--check icon-check"></i><i class="b-icon b-icon--close icon-close"></i>
								</div>
							</div>
						</div>
						<div class="b-form__group b-form__group">
							<div class="b-form__left">
								<label class="b-label" for="phone">Телефон
								</label>
							</div>
							<div class="b-form__right">
								<div class="b-input">
                           <input v-model="obj.phone" class="b-input__input-field" id="phone" type="tel" placeholder="Введите номер телефона" maxlength="15"  v-phone/>
                           <i class="b-icon b-icon--check icon-check"></i><i class="b-icon b-icon--close icon-close"></i>
								</div>
							</div>
						</div>
						<div class="b-form__group b-form__group">
							<div class="b-form__left">
								<label class="b-label" for="email-developer">E-mail
								</label>
							</div>
							<div class="b-form__right">
								<div class="b-input">
									<input v-model="obj.email" class="b-input__input-field" id="email-developer" type="email" placeholder="Введите e-mail пользователя" name="email-developer"/>
									<i class="b-icon b-icon--check icon-check"></i><i class="b-icon b-icon--close icon-close"></i>
								</div>
							</div>
						</div>
					</div>
					<div class="b-form__group b-form__group--button">
						<button @click="save(obj)" class="b-button b-button--fill b-button--full">Сохранить</button>

						<router-link class="b-button b-button--empty b-button--full" :to="'/Companies'" title="Отменить">Отменить</router-link>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import iconAngleDownSvg from "../../assets/icons/svg/angle-down.svg"
import iconAngleleftSvg from "../../assets/icons/svg/angle-left.svg"
import {mapActions} from 'vuex'

export default {

	components: {
		iconAngleleftSvg,
		iconAngleDownSvg
	},
	name: 'icn',
	data: () => ({
		disabledInpRole: false,
		obj: {
         name: "",
			email: "",
			inn: null,
			address: "",
			phone: "",
		},
		listCompanies: [],
	}),
	methods: {
		...mapActions({
			apiAll: 'api/apiAll'
		}),
		save(obj) {
				this.apiAll({type: `${'post'}`, url: `api/Companies/CheckFns`, id:undefined, obj: obj})
				.then(res => {
					console.log(res);
					if( res.data == true ) {
						if (obj.name.length > 3 || obj.email.length > 4) {
							this.apiAll({type: `${this.$route.params.id ? 'put' : 'post'}`, url: `api/Companies`, id: this.$route.params.id ? obj.id : undefined, obj: obj})
							.then(res => {
								console.log(res.data);
								if(res.status == 201 || res.status == 200 ) {
									this.$notify({ group: 'foo', duration: 5000, type: 'success', title: 'Успешно', text: `${this.$route.params.id ? 'Организация успешно редактирована' : 'Организация успешно создана'}` })
									this.obj = { email: "", phone: "", name: "", address: "", inn: "" }
									this.$router.push(`${'/Companies'}`)
								} else {
									this.$notify({ group: 'foo', duration: 5000, type: 'error', title: 'Ошибка', text: 'Произошла ошибка при создании организации' })
								}
							})
							.catch(error => {
								if(error) {
									if(error.status == 409){
										this.$notify({ group: 'foo', duration: 5000, type: 'error', title: 'Ошибка при создании', text: 'Организация с данным email уже существует в Системе' })
									} else {
										this.$notify({ group: 'foo', duration: 5000, type: 'error', title: 'Ошибка при создании', text: 'Заполните все поля...' })
									}
								}
				})
			} else {
				this.$notify({ group: 'foo', duration: 5000, type: 'error', title: 'Заполните обязательные поля', text: 'Заполните все поля...' })
			}
					} else {
						this.$notify({ group: 'foo', duration: 5000, type: 'error', title: 'Ошибка', text: 'Произошла ошибка при создании организации' })
					}
				})
		}
	},
	created() {
      if(this.$route.params.id){
         this.apiAll({type: 'get', url: `api/Companies/${this.$route.params.id}`, id: undefined})
		.then(res => {
			this.obj = Object.assign({}, res.data)
		})
      }
		this.apiAll({type: 'get', url: `api/Companies`, id: undefined}).then(res => {
			this.listCompanies = res.data
			this.listCompanies.sort(function (a, b) {
			  if (a.name > b.name) return 1
			  if (a.name < b.name) return -1
			  return 0
			});
		})
	}

}
</script>

<style>
@import '../../style/stylePages.css';

.b-select__select-field[disabled] {
    color: black!important;
	cursor: not-allowed;
}

.vs__dropdown-toggle {
	border-radius: 0;
	border: 1px solid #e5e5eb;
}

.vs__actions {
	display: none;
}
</style>
